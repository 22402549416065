<template lang="pug">
    .users.view
        .content-wrapper
            .header
                .h2 {{ title }}
                el-button(@click="$router.push({ name: 'subscriber.edit' })" icon="el-icon-plus" type="success" size="small" plain) Добавить пользователя
            .totals-cards
                div
                    b ?
                    div
                        div всего
                div
                    b ?
                    div
                        div за квартал
                div
                    b ?
                    div
                        div за месяц
                div
                    b ?
                    div
                        div сегодня
            .content.full-width.transparent
                .title
                    .h1 Недавние
                    div
                        el-button(icon="el-icon-s-operation" type="primary" size="small" plain)
                .table
                    .th
                        .td Имя
                        .td Компания
                        .td Роль
                        .td Дата
                    .tr(v-for="row in table_data" @click="$router.push({ name: 'subscriber.details', params: { id: row.id } })" class="clickable" :key="row.id")
                        .td
                            b {{ row.name }}
                        .td
                            b X5
                        .td(class="color-accent--success") Администратор
                        .td {{ row.date }}
                .pagination(v-if="false")
                    el-button(:plain="false" type="primary" size="small" ) 1
                    el-button(type="primary" size="small" plain) 2
                    el-button(type="primary" size="small" plain) 3
</template>

<script>
    export default {
        metaInfo() {
            return {
                title: this.title
            }
        },
        data() {
            return {
                table_data: [
                    {
                        id: 1,
                        phone: '79660000000',
                        date: '2016-05-03',
                        name: 'Aleksandr',
                    },
                    {
                        id: 2,
                        phone: '79660000000',
                        date: '2016-05-03',
                        name: 'Yuriy',
                    },
                ]
            }
        },
        computed: {
            title(){
                return 'Пользователи'
            },
        },
    }
</script>

<style lang="scss" scoped>
    .users{
        .header{
            display: flex;
            align-items: center;

            .h3{
                margin-left: $padding-global;
            }
        }
        .totals-cards{
            padding-top: $padding-global;
        }
        .title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: $padding-global;
        }
        .table{
            padding-top: $padding-global;
        }
    }
</style>
